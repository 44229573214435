import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  walletAddress: any
  constructor(private router: Router, private toastr: ToastrService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let account: any = localStorage.getItem('wagmi.store');
    account = JSON.parse(account);
    const admin = account.state.data.account;
    const supportedChainId = environment.SUPPORTED_CHAINID;

    if (admin && supportedChainId == account.state.data?.chain.id) {
      return true;
    } else if (supportedChainId != account.state.data?.chain.id) {
      this.toastr.error('Connect to the supported chain');
      return this.router.navigate(['/']);
    } else {
      return this.router.navigate(['/']);
    }
  }
}
