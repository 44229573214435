import { Routes } from '@angular/router';
import { LoginComponent } from './components/auth/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AuthGuard } from './helpers/auth.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./components/auth/login/login.component').then(m => m.LoginComponent)
  },
  {
    path: 'transaction-management',
    loadComponent: () => import('./components/transaction-management/transaction-management.component').then(m => m.TransactionManagementComponent),
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    loadComponent: () => import('./components/page-not-found/page-not-found.component').then(m => m.PageNotFoundComponent),
  },

];
