import { Component, OnInit } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { initFlowbite } from 'flowbite';
import { WalletConnectService } from 'src/app/services/wallet-connect.service';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Sunwave-admin';
  private walletAddress:any
  constructor(
    private walletConnectService:WalletConnectService,
    private route:ActivatedRoute,
    private location: Location

  ){}
  ngOnInit(): void {
    initFlowbite();
  }
  
}
